import React from "react"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"
import Button from "react-bootstrap/Button"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <ContextProvider>
    <Layout>
      <SEO title="Página No Encontrada" />
      <div className={classes.root}>
        <Banner content="404 Página No Encontrada" />
        <div className={classes.errorPage}>
          <h3>Al Parecer Esta Página No Existe...</h3>
          <Link to="/">
            <Button variant="outline-secondary">Volver Al Inicio</Button>
          </Link>
        </div>
      </div>
    </Layout>
  </ContextProvider>
)

export default NotFoundPage
